import { Box } from "@bigcommerce/big-design";
import AttraqtLogo from "../images/attraqt-logo.svg";

const PageFooter = () => {
    return (
        <Box style={{textAlign: "center"}} marginTop="large">
            <a href="https://www.attraqt.com?utm_source=bigcommerce-app&utm_medium=link&utm_campaign=footer" target="_blank" rel="noreferrer"><img src={AttraqtLogo} alt="Attraqt" style={{width: "75px"}} /></a>
        </Box>
    );
}

export default PageFooter;