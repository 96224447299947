import { useEffect, useState } from "react";
import axios from "axios";
import { Message } from "@bigcommerce/big-design"

type CheckTokenStateProps = {
}

const CheckTokenState = (props: CheckTokenStateProps): JSX.Element => {
    const [tokenState, setTokenState] = useState<boolean>();

    useEffect(() => {
        axios.get("/api/attraqt/refresh_token_state").then((response) => {
            setTokenState(response.data.data.expired);
        });
    }, [])

    return (tokenState === false ? <Message
        header="Integration Activated"
        messages={[
            {
                text: "You've successfully activated your XO account and connected to your BigCommerce store. Use the interface below to configure your required options.",
            },
        ]}
        marginBottom="large"
    /> : ((tokenState === true) ? <Message
        header="Re-authentication Required"
        messages={[
            {
                text: "The link between Attraqt and BigCommerce has expired.",
            },
        ]}
        type="warning"
        marginBottom="large"
        actions={[
            {
                text: "Refresh",
                onClick: () => { window.open('/auth/start', '_blank', 'opener'); setTokenState(undefined) }
            }]}
    /> : <></>))
}

export default CheckTokenState;