import React, { useEffect, useState } from "react";
import axios from "axios";

type TenantsSelectorProps = {
    style?: React.CSSProperties;
    onSelect: Function;
}

type Tenant = {
    id: string;
    name: string;
}

const TenantsSelector = (props: TenantsSelectorProps): JSX.Element => {
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<any>();

    useEffect(() => {
        axios.get("/api/attraqt/tenants").then((response) => {
            setTenants(response.data.data);
        });
    }, []);

    useEffect(() => {
        if (tenants.length > 0 && selectedTenant === undefined) {
            setSelectedTenant(tenants[0]);
        }
    }, [props, tenants, selectedTenant]);

    useEffect(() => {
        props.onSelect(selectedTenant);
    }, [props, selectedTenant]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const tenantSelected = tenants.find((t) => t.id === event.target.value);
        setSelectedTenant(tenantSelected);
    }

    return <select onChange={handleChange} defaultValue={selectedTenant?.id} {...props as any}>
        {
            tenants.map((item) => <option value={item.id} key={item.id}>{item.name} ({item.id})</option>)
        }</select>;
}

export default TenantsSelector;