import { CreateSortOption, CreateSortOptionProps } from "./CreateSortOption";
import { SortOption } from "../../../../../src/lib/app/db/sortOptions";
import { ViewSortOption, ViewSortOptionProps } from "./ViewSortOption";
import { useState, useEffect, useCallback } from "react";
import { H2, Small } from "@bigcommerce/big-design";

type CustomSortOptionProps = {};

const CustomSortOption = (props: CustomSortOptionProps): JSX.Element => {
  const [processing, setProcessing] = useState<boolean>(true);
  const [sortOptions, setSortOptions] = useState<Array<SortOption>>([]);

  const setOptionTableData = () => {
    fetch('api/sort-options').then((res) => res.json()).then((res) => {
      setProcessing(true);
      const options: SortOption[] = res?.data?.sortOptions;
      setSortOptions(options.sort((option1: SortOption, option2: SortOption) => option1.sortOrder - option2.sortOrder));
      setProcessing(false);
    }).finally(() => {
      setProcessing(false);
    })
  };

  useEffect(setOptionTableData, []);

  const handleReloadSortOptions = useCallback(setOptionTableData, []);

  const updateSortOptions = async (sortOptions: SortOption[]) => {
    try {
      setProcessing(true);
      await Promise.all(sortOptions.map(async sortOption => {
        return fetch(`/api/sort-option/${sortOption.id}`, {
          method: 'put',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ ...sortOption })
        }).then(response => response);
      }));
      await fetch("/api/debug/publishScripts", {
        method: "post"
      });
      handleReloadSortOptions();
    } finally {
      setProcessing(false);
    }
  }

  const viewSortOptionProps: ViewSortOptionProps = {
    processing,
    sortOptions,
    handleReloadSortOptions,
    updateSortOptions,
  }

  const createSortOptionProps: CreateSortOptionProps = {
    sortOptions,
    handleReloadSortOptions,
  }

  return <>
    <H2 marginVertical="large">Sort Options</H2>
    <Small>Enable front-end users to choose how they would like the products they’re browsing sorted.</Small>
    <ViewSortOption {...viewSortOptionProps} />
    <CreateSortOption {...createSortOptionProps} />
  </>;
}

export default CustomSortOption;
