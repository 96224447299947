import { GlobalStyles, Box, H0, Text, Flex, FlexItem } from "@bigcommerce/big-design";
import "./app.css";
import {
  Route,
  BrowserRouter,
  Redirect
} from "react-router-dom";
import Dashboard from "./routes/Dashboard"
import Help from "./routes/Help"
import NotFound from "./routes/NotFound";
import Onboarding from "./routes/Onboarding"
import Debug from "./routes/Debug"
import AuthReturn from "./routes/AuthReturn"
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { useContext } from "react";
import SwitchTransition from "./components/SwitchTransition";
import StoreInfo from "./routes/StoreInfo";

const Wrapper = () => {
  return <AuthProvider>
    <App />
  </AuthProvider>;
}

function App() {
  const { loggedIn, attraqt, featureFlags } = useContext(AuthContext);

  const onboarded = attraqt.authed && attraqt.tenant !== null;

  const appRouter = (
    <BrowserRouter>
      <SwitchTransition>
        <Route exact path="/">
          {!onboarded ? <Redirect to="/onboarding" /> : <Dashboard />}
        </Route>
        <Route exact path="/help" component={Help} />
        <Route exact path="/refreshStore" component={StoreInfo} />
        <Route exact path="/debug">
          {featureFlags?.debugMenuAccess ? <Debug /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/auth/success" component={AuthReturn} />
        <Route exact path="/onboarding">
          {onboarded ? <Redirect to="/" /> : <Onboarding />}
        </Route>
        <Route path="*" component={NotFound} />
      </SwitchTransition>
    </BrowserRouter>
  );

  const userError = (
    <Flex style={{ height: '50vh' }} justifyContent="center" flexDirection="column">
      <FlexItem>
        <H0>Uh oh, you're not logged in.</H0>
        <Text>You'll need to access this app through your BigCommerce store. If you're already there then try refreshing this page.</Text>
      </FlexItem>
    </Flex>
  );

  return (
    <Box style={{ maxWidth: "1200px", marginLeft: "auto", marginRight: "auto" }} paddingHorizontal="large">
      <GlobalStyles />
      <Box>
        {loggedIn ? appRouter : userError}
      </Box>
    </Box>
  );
}

export default Wrapper;
