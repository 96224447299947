import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import LogOutputArea from "../components/LogOutput";
import { H2, H3, Text, Button, Panel, Flex, FlexItem, Switch as BCSwitch, Popover } from "@bigcommerce/big-design";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export interface AuthDataResponseModel {
    access_token: string;
    refresh_token: string;
    expires_at: number;
}

const RepublishScriptsButton = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [logOutput, setLogOutput] = useState(null);
    const publishScripts = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setLogOutput(null);
        setIsLoading(true);

        axios.post("/api/debug/publishScripts").then((response) => {
            setLogOutput(response.data.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return <>
        <Button onClick={publishScripts} isLoading={isLoading}>Re-publish Scripts</Button>
        <LogOutputArea title="Scripts Debug Output" output={logOutput} styleWhenVisible={{ marginTop: "10px" }} />
    </>;
}

const RefreshAccessTokenButton = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [attraqtAuthData, setAttraqtAuthData] = useState<AuthDataResponseModel | null>(null);

    const getAttraqtAuth = useCallback(() => {
        axios.get("/api/debug/viewAttraqtAuth").then((response) => {
            setAttraqtAuthData(response.data.data as AuthDataResponseModel);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        getAttraqtAuth();
    }, [getAttraqtAuth]);

    const refreshAccessToken = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsLoading(true);

        axios.get("/api/debug/refreshStore").then((result) => {
            console.log(result);
        });

        axios.post("/api/debug/refreshAttraqtAccessToken").then((response) => {
            setAttraqtAuthData(response.data.data as AuthDataResponseModel);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return <>
        <Text bold>Access Token</Text>
        <textarea style={{ width: "100%" }} rows={5} value={attraqtAuthData?.access_token ?? ''}></textarea>

        <Text bold marginTop="large">Refresh Token</Text>
        <textarea style={{ width: "100%" }} rows={5} value={attraqtAuthData?.refresh_token ?? ''}></textarea>

        <Text bold marginTop="large">Expires At</Text>
        <textarea style={{ width: "100%" }} rows={3} value={attraqtAuthData?.expires_at ?? ''}></textarea>

        <Button onClick={refreshAccessToken} isLoading={isLoading} marginTop="xLarge">Refresh Access Token</Button>
    </>;
}

const AddWidgetToPageBuilderButton = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
    const [popoverMessage, setPopoverMessage] = useState<{ message: string, type: 'error' | 'success', color: 'danger' | 'success' } | null>(null);

    const addWidgetToPageBuilder = () => {
        setIsLoading(true);

        axios.post("/api/debug/addWidgetToPageBuilder").then((response) => {
            setPopoverMessage({ message: 'Widget list has been refreshed successfully', type: 'success', color: 'success' });
            setIsPopoverOpen(true);
        }).catch((error) => {
            setPopoverMessage({ message: 'Refreshing widget list has failed. Please try again', type: 'error', color: 'danger' });
            setIsPopoverOpen(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return <>
        <Button ref={setButtonRef} onClick={() => { addWidgetToPageBuilder() }} isLoading={isLoading} type="button">
            Refresh Widget List
        </Button>
        <Popover
            distance={15}
            padding="small"
            backgroundColor={popoverMessage?.color}
            anchorElement={buttonRef}
            isOpen={isPopoverOpen}
            label="Refresh Widget List"
            onClose={() => setIsPopoverOpen(false)}
        >
            <span style={{ color: "white" }}>{popoverMessage?.message}</span>
        </Popover>
        {/* <LogOutputArea title="Widgets Debug Output" output={logOutput} styleWhenVisible={{ marginTop: "10px" }} /> */}
    </>;
}

type DebugProps = {};

const Debug = (props: DebugProps): JSX.Element => {
    const [disableWidgetCache, setDisableWidgetCache] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const getCachingStatus = useCallback(() => {
        setIsLoading(true);
        axios.get("/api/store/getWidgetCachingStatus").then((response) => {
            setDisableWidgetCache(response.data.store["disableWidgetCache"])
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const setCachingStatus = (input: boolean) => {
        setIsLoading(true);
        axios.post(`/api/store/setWidgetCachingStatus`, { disableWidgetCache: input })
            .then((response) => {
                setDisableWidgetCache(input);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getCachingStatus();
    }, [getCachingStatus]);

    return <>
        <PageHeader backlink="/" />
        <H2 marginTop="large">Debug</H2>
        <Text>Run processes, view values and generally debug the XO Integration to understand the state of the system.</Text>

        <Panel>
            <H3>Scripts API</H3>
            <Text>The Scripts API is used to publish any configuration managed in the App and any JS that needs to be loaded on the frontend of the store to power various features. These scripts are republished at various points in the user journey but to force a public now, click the button below.</Text>
            <RepublishScriptsButton />
        </Panel>

        <Panel>
            <H3>Attraqt Authentication</H3>
            <Text>The App connects to the Attraqt API to fetch data and configure services via OIDC + PKCE.</Text>
            <RefreshAccessTokenButton />
        </Panel>

        <Panel>
            <H3>Refresh Widget List</H3>
            <Text>Recommendations Widgets are added to the page builder via drag and drop</Text>
            <AddWidgetToPageBuilderButton />
        </Panel>

        <Panel>
            <H3>Disable Widget Cache</H3>
            <Text>This will allow all API calls to be made with no caching.</Text>
            <Flex marginVertical="medium">
                <FlexItem marginRight="medium" style={{ paddingTop: "4px" }}>
                    <BCSwitch checked={disableWidgetCache} onChange={() => setCachingStatus(!disableWidgetCache)} disabled={isLoading} />
                </FlexItem>
            </Flex>
        </Panel>

        <PageFooter />
    </>;
}
export default Debug;