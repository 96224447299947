import { Input, Modal, Textarea, Text, Small, Switch as BCSwitch, Flex, FlexItem, Tooltip, Link } from "@bigcommerce/big-design";
import { InfoIcon, ErrorIcon } from "@bigcommerce/big-design-icons";
import React, { useState, useCallback } from "react";
import { WidgetTemplate } from "../../../../../src/lib/app/db/widgetTemplates";
import { RecommendationResponse } from "../../../../../embed/src/widget";
import ejs from "ejs";

export type WidgetTemplateFormProps = {
  action: string,
  isOpen: boolean,
  storeHash: string,
  handleClose: () => void,
  apiMethod: string,
  apiPath: string,
  modalHeader: string,
  widgetTemplateData: TemplateData,
  handleTemplateNameChange: (name: string) => void,
  handleTemplateDataChange: (templateData: string) => void,
  handleTemplateCodeChange: (templateCode: string) => void,
  handleIsDeveloperTemplateChange: () => void,
  handleReloadTemplates: () => void,
};

type TemplateData = Pick<WidgetTemplate, "isDeveloperTemplate" | "templateName"> & { templateData: string, templateCode: string };

export const alphaNumericPattern = "^[-a-zA-Z0-9_]+$";

const testRecommendationData: RecommendationResponse = {
  id: "testId",
  recommendations: [
    {
      product: {
        booster: 123,
        context: "textcontext",
        image: "testurl",
        metadata: { updated: "testmetadata" },
        updated: "testdata",
        photo: "testurl",
        price: "30",
        sale_price: "35",
        recommended: true,
        title: "test product",
        type: "test type",
        custom_url: "test url",
        version: 123,
        thumb_urls: "test url",
        _id: {
          kind: "variant",
          original_id: "21",
          tenant: "6051c020cdf2f91094b2ede1",
        },
        url: "test url",
      }
    }
  ],
  statusCode: 200
}

const ejsTestData = {
  data: testRecommendationData,
  widgetId: "testId",
  widgetTemplate: "testTemplate",
  recommendationId: testRecommendationData.id,
  currencySymbol: "£",
}

const inBuiltTemplateNames = [
  "carousel",
  "content banner",
  "content",
  "banner & products",
  "content-products"
];

export const WidgetTemplateForm = (props: WidgetTemplateFormProps): JSX.Element => {
  const templateCodeErrorMsg = "Template Code can only contain alphanumeric characters";
  const templateNameErrorMsg = "Inbuilt template names `Carousel`, `Content Banner`, `content`, `Banner & Products` and `content-products` cannot be used as custom template name";

  const [saving, setSaving] = useState<boolean>(false);
  const [templateCodeError, setTemplateCodeError] = useState<string>("");
  const [templateDataError, setTemplateDataError] = useState<string>("");
  const [templateNameError, setTemplateNameError] = useState<string>("");

  const handleIsDeveloperTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.widgetTemplateData.isDeveloperTemplate) {
      setTemplateCodeError("");
    } else {
      setTemplateDataError("");
    }
    props.handleIsDeveloperTemplateChange();
  }

  const handleTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    inBuiltTemplateNames.includes(target.value.toLowerCase()) ? setTemplateNameError(templateNameErrorMsg) : setTemplateNameError("");
    props.handleTemplateNameChange(target.value);
  }

  const handleTemplateCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateCodeError("");
    const { target } = event;
    const regex = RegExp(alphaNumericPattern, "g");
    regex.test(target.value) ? setTemplateCodeError("") : setTemplateCodeError(templateCodeErrorMsg);
    props.handleTemplateCodeChange(target.value);
  }

  const handleTemplateDataChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplateDataError("");
    const { target } = event;
    props.handleTemplateDataChange(target.value);
    try {
      ejs.render(props.widgetTemplateData.templateData, ejsTestData);
    } catch (e) {
      setTemplateDataError(String(e));
    }
  }

  const handleTemplateModalCancel = useCallback(() => {
    setTemplateCodeError("");
    setTemplateDataError("");
    props.handleClose();
  }, [props]);

  const handleTemplateFormSubmit = useCallback(async () => {
    try {
      if (props.widgetTemplateData.templateName !== "" && templateNameError === "" && templateDataError === "" && templateCodeError === "") {
        if (
          (!props.widgetTemplateData.isDeveloperTemplate && props.widgetTemplateData.templateData !== "") ||
          (props.widgetTemplateData.isDeveloperTemplate && props.widgetTemplateData.templateCode !== "")
        ) {
          setSaving(true);
          await fetch(props.apiPath, {
            method: props.apiMethod,
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({ ...props.widgetTemplateData })
          });
          await fetch("/api/debug/addWidgetToPageBuilder", {
            method: "post"
          });
          await fetch("/api/debug/publishScripts", {
            method: "post"
          });
          handleTemplateModalCancel();
          props.handleReloadTemplates();
        }
      }
    } finally {
      setSaving(false);
    }
  }, [templateDataError, templateNameError, templateCodeError, props, handleTemplateModalCancel]);

  const templateDataDescription = "Here you can create & update widget templates. Once you’ve created a widget template, it will appear as part of the dropdown menu when adding a recommendations widget in BC page builder.";

  const templateDataInput = <Textarea
    label="Template Data"
    placeholder="Please enter your data in EJS format here."
    description={templateDataDescription}
    required
    value={props.widgetTemplateData.templateData}
    error={templateDataError}
    onChange={handleTemplateDataChange}
    disabled={props.widgetTemplateData.isDeveloperTemplate}
  />;

  const templateCodeDescription = `Here you can create & update your own custom widget templates. This is for admins who would like to have personalised widget templates and/or would like to use their own code instead of following EJS. ` +
    `They'd need to implement `;
  const templateCodeDescriptionFinalPart = ` in their template. `;

  const storeThemeSettingUrl = `https://store-${props.storeHash}.mybigcommerce.com/manage/storefront-manager/my-themes`;

  const templateCodeInput = <>
    <Flex><Text bold>Template Code</Text></Flex>
    <Flex marginVertical="xxSmall">
      <Small>
        {templateCodeDescription}<span style={{ fontFamily: "monospace" }}>&nbsp;window.xobc.delegateFetchWidgetTemplate(templateCode)&nbsp;</span>{templateCodeDescriptionFinalPart}
        <Link style={{ fontSize: "14px" }} href={storeThemeSettingUrl}>
          <u>Click here to manage your templates.</u>
        </Link>
      </Small>
    </Flex>
    <p>
      <Input
        type="text"
        placeholder="Please enter your custom template code"
        required
        value={props.widgetTemplateData.templateCode}
        error={templateCodeError}
        onChange={handleTemplateCodeChange}
        disabled={!props.widgetTemplateData.isDeveloperTemplate}
      />
    </p>
  </>;

  const widgetTemplateForm =
    <p>
      <Flex>
        <FlexItem marginRight="small" style={{ paddingTop: "10px" }}>
          <BCSwitch checked={props.widgetTemplateData.isDeveloperTemplate} onChange={handleIsDeveloperTemplateChange} disabled={saving} />
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem marginRight="xxSmall" style={{ paddingTop: "4px" }}>
              <Text>Developer Template</Text>
            </FlexItem>
            <FlexItem style={{ paddingTop: "6px" }}>
              <Tooltip trigger={<InfoIcon color="secondary50" size="large" />} placement="right">
                <Small>Select Developer template if you would like to add your own custom widget template.</Small>
              </Tooltip>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
      <p>
        <Input
          label="Template Name"
          type="text"
          required
          description="This name will be used in lists for internal use. Make sure it is descriptive and understandable."
          placeholder="Please enter a template name"
          value={props.widgetTemplateData.templateName}
          error={templateNameError}
          onChange={handleTemplateNameChange}
          disabled={saving}
        />
        <Flex marginVertical="xSmall">
          <FlexItem style={{ paddingTop: "4px" }}>
            <Small hidden={templateNameError === ""}>
              <ErrorIcon color="danger50" size="xxLarge" />
            </Small>
          </FlexItem>
          <FlexItem>
            <Small hidden={templateNameError === ""} color="danger50" >
              {templateNameError}
            </Small>
          </FlexItem>
        </Flex>
      </p>
      <p>
        {props.widgetTemplateData.isDeveloperTemplate ? templateCodeInput : templateDataInput}
        <Flex marginVertical="xSmall">
          <FlexItem>
            <Small hidden={props.widgetTemplateData.isDeveloperTemplate ? templateCodeError === "" : templateDataError === ""}>
              <ErrorIcon color="danger50" size="xLarge" />
            </Small>
          </FlexItem>
          <FlexItem style={{ paddingTop: "2px" }}>
            <Small hidden={props.widgetTemplateData.isDeveloperTemplate ? templateCodeError === "" : templateDataError === ""} color="danger50" >
              {props.widgetTemplateData.isDeveloperTemplate ? templateCodeError : templateDataError}
            </Small>
          </FlexItem>
        </Flex>
      </p>
    </p>;

  return <>
    <br />
    <Modal
      actions={[
        { text: 'Cancel', variant: 'subtle', onClick: handleTemplateModalCancel },
        { text: props.action, onClick: handleTemplateFormSubmit },
      ]}
      header={props.modalHeader}
      isOpen={props.isOpen}
      onClose={props.handleClose}
      closeOnEscKey={true}
      closeOnClickOutside={false}
    >
      {widgetTemplateForm}
    </Modal>
  </>;
}
