import { Button } from "@bigcommerce/big-design";
import { useState } from "react";
import { SortOption } from "../../../../../src/lib/app/db/sortOptions";
import { SortOptionForm, SortOptionFormProps } from "./SortOptionForm";

export type CreateSortOptionProps = {
  sortOptions: SortOption[],
  handleReloadSortOptions: () => void,
};

export const CreateSortOption = (props: CreateSortOptionProps): JSX.Element => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [label, setLabel] = useState<string>("");
  const [attribute, setAttribute] = useState<string>("");
  const [direction, setDirection] = useState<"asc" | "desc">("asc");

  const handleLabelChange = (label: string) => {
    setLabel(label);
  }

  const handleAttributeChange = (attribute: string) => {
    setAttribute(attribute);
  }

  const handleDirectionChange = (direction: "asc" | "desc") => {
    setDirection(direction);
  }

  const HandleSortOptionAddClick = () => {
    setIsFormOpen(true);
  }

  const handleSortOptionFormClose = () => {
    setLabel("");
    setDirection("asc");
    setAttribute("");
    setIsFormOpen(false);
  }

  const handleReloadSortOptions = () => {
    props.handleReloadSortOptions();
  }

  const sortOptionFormProps: SortOptionFormProps = {
    action: "Create",
    isOpen: isFormOpen,
    handleClose: handleSortOptionFormClose,
    handleLabelChange,
    handleAttributeChange,
    handleDirectionChange,
    handleReloadSortOptions,
    apiMethod: "post",
    apiPath: "api/sort-option",
    modalHeader: "Create a sort option",
    sortOptionData: {
      attribute,
      label,
      direction,
      sortOrder: props.sortOptions.length !== 0 ? props.sortOptions.length : 0,
    }
  }

  return <>
    <Button onClick={HandleSortOptionAddClick}>Add Sort Options</Button>
    <br />
    <SortOptionForm {...sortOptionFormProps} />
  </>;
}
