import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import CustomWidgetTemplate from "../components/BigCommerce/CustomWidgetTemplate/CustomWidgetTemplate";
import CustomSortOption from "../components/BigCommerce/CustomSortOption/CustomSortOption";
import CustomFacetConfiguration from "../components/BigCommerce/CustomFacetConfiguration/CustomFacetConfiguration";
import CheckTokenState from "../components/Attraqt/CheckTokenState";
import {
  H1,
  H2,
  Form,
  FormGroup,
  Input,
  Box,
  Button,
  Flex,
  FlexItem,
  Text,
  Small,
  Switch as BCSwitch,
} from "@bigcommerce/big-design";
import { useState, useEffect, useCallback } from "react";
// import axios from "axios";

type DashboardProps = {};

const Dashboard = (props: DashboardProps): JSX.Element => {
  const [trackerId, setTrackerId] = useState<string>("");
  const [taxRate, setTaxRate] = useState<number>(0);
  //const [webhooksEnabled, setWebhooksEnabled] = useState<boolean>(false);
  const [searchEnabled, setSearchEnabled] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  /**
   * Fetch the current configuration by making a GET request to the server via API call.
   * After the request is complete, sets the state of the webhooks.
   * See the function in getStoreRegisteredWebhooks in src/routes/api/store/webhooks/getWebhooksStatus.ts.
   */
  // const getWebhooksStatus = useCallback(() => {
  //   setLoading(true);
  //   axios
  //     .get("/api/store/getWebhooksStatus")
  //     .then((response) => {
  //       setWebhooksEnabled(response.data.webhooksEnabled);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  /**
   * Saves the state of the webhooks to the value of the switch.
   * After the request is complete, gets the current webhooks status from the server via API call.
   * @param {boolean} value - The value of the switch.
   * See the function setWebhooksStatus in src/routes/api/store/webhooks/setWebhooksStatus.ts
   */
  // const saveWebhooksStatus = (input: boolean) => {
  //   setLoading(true);
  //   axios
  //     .post(`/api/store/setWebhooksStatus`, { webhooksEnabled: input })
  //     .then((response) => {
  //       getWebhooksStatus();
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    setLoading(true);
    fetch("/api/config")
      .then((res) => res.json())
      .then((res) => {
        setTaxRate(res?.taxRate);
        setTrackerId(res?.trackerId);
        setSearchEnabled(res?.searchEnabled ?? false);
        // setWebhooksEnabled(res?.webhooksEnabled ?? false);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    // getWebhooksStatus();
  }, [/*getWebhooksStatus*/]);

  const handleConfigurationSave = useCallback(async () => {
    try {
      setSaving(true);
      await fetch("/api/config", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          trackerId,
          taxRate,
          searchEnabled,
        }),
      });
    } finally {
      setSaving(false);
    }
  }, [trackerId, taxRate, searchEnabled]);

  useEffect(() => {
    handleConfigurationSave();
  }, [handleConfigurationSave]);

  return (
    <>
      <PageHeader />

      <br />
      <CheckTokenState />

      <br />
      <H1 marginVertical="large">Integration Overview</H1>

      <br />
      <Box
        backgroundColor="secondary30"
        border="box"
        borderRadius="normal"
        padding="medium"
      >
        <H2>Configuration</H2>
        <Form>
          <FormGroup>
            <Input
              label="Tracker ID"
              type="text"
              required
              description="Please enter your Tracker Key. This can be found in the Onboarding Portal under API Keys. If you're unable to locate this, please contact your CSM."
              value={trackerId}
              onChange={(e) => setTrackerId(e.target.value)}
              disabled={loading}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Tax Rate"
              type="number"
              description="If required, specify a tax rate (as a percentage) to apply to prices before being displayed in recommendations or search results."
              value={taxRate}
              onChange={(e) => setTaxRate(Number(e.target.value))}
              disabled={loading}
            />
          </FormGroup>
          <Box marginTop="xxLarge">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleConfigurationSave();
              }}
              isLoading={saving}
              disabled={loading}
            >
              Save Features and Configurations
            </Button>
          </Box>
        </Form>
      </Box>
      <br />

      <br />
      <H2 marginVertical="large">Features</H2>

      <Flex marginVertical="medium">
        <FlexItem marginRight="small" style={{ paddingTop: "4px" }}>
          <BCSwitch
            checked={searchEnabled}
            onChange={() => setSearchEnabled(!searchEnabled)}
            disabled={loading}
          />
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem marginRight="xSmall">
              <Text bold>Search</Text>
            </FlexItem>
            <FlexItem style={{ paddingTop: "3px" }}>
              <Small>
                Power your on-site quick and full search with Attraqt! Before
                you enable Search, please first review your search configuration
                within XO.
              </Small>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>

      <Flex marginVertical="medium">
        <FlexItem marginRight="small" style={{ paddingTop: "4px" }}>
          <BCSwitch checked={true} disabled />
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem marginRight="xSmall">
              <Text bold>Data Tracking</Text>
            </FlexItem>
            <FlexItem style={{ paddingTop: "3px" }}>
              <Small>
                Send user activity and purchasing behaviour to Attraqt.
              </Small>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>

      <Flex marginVertical="medium">
        <FlexItem marginRight="small" style={{ paddingTop: "4px" }}>
          <BCSwitch checked={true} disabled />
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem marginRight="xSmall">
              <Text bold>Recommendation Widgets</Text>
            </FlexItem>
            <FlexItem style={{ paddingTop: "3px" }}>
              <Small>Add Attraqt widgets to Page Builder.</Small>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>

      {/* <Flex marginVertical="medium">
        <FlexItem marginRight="small" style={{ paddingTop: "4px" }}>
          <BCSwitch
            checked={webhooksEnabled}
            onChange={() => saveWebhooksStatus(!webhooksEnabled)}
            disabled={loading}
          />
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem marginRight="xSmall">
              <Text bold>Webhooks</Text>
            </FlexItem>
            <FlexItem style={{ paddingTop: "3px" }}>
              <Small>Enable or disable webhooks sending data to Attraqt.</Small>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex> */}

      <CustomWidgetTemplate />

      <CustomSortOption />

      <CustomFacetConfiguration />

      <PageFooter />
    </>
  );
};

export default Dashboard;
