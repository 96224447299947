import { createContext, useEffect, useState } from "react";
import axios from "axios";

const AuthContext = createContext<AuthState>({
    loggedIn: true,
    attraqt: {
        authed: true,
        tenant: undefined
    },
    refresh: () => { }
});

type AuthProviderProps = {
    children?: JSX.Element
}

type AuthState = {
    loggedIn: boolean;
    attraqt: {
        authed: boolean;
        tenant: string | undefined | null;
    }
    shop?: {
        storeHash: string;
        name: string;
        domain: string;
    },
    featureFlags?: {
        debugMenuAccess?: boolean;
        quickSearchEnabled?: boolean;
        fullSearchEnabled?: boolean;
    },
    refresh: Function;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [loggedIn, setLoggedIn] = useState(true);
    const [attraqtAuthed, setAttraqtAuthed] = useState(true);
    const [attraqtTenant, setAttraqtTenant] = useState<string | null | undefined>(undefined);
    const [shop, setShop] = useState(undefined);
    const [debugMenuAccess, setDebugMenuAccess] = useState(false);
    const [quickSearchEnabled, setQuickSearchEnabled] = useState(false);
    const [fullSearchEnabled, setFullSearchEnabled] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const state: AuthState = {
        loggedIn,
        shop,
        attraqt: {
            authed: attraqtAuthed,
            tenant: attraqtTenant,
        },
        featureFlags: {
            debugMenuAccess,
            quickSearchEnabled,
            fullSearchEnabled,
        },
        refresh: () => {
            setRefresh(true);
        }
    };

    useEffect(() => {
        axios.get("/api/me").then((response) => {
            setLoggedIn(true);
            setAttraqtAuthed(response.data.data.attraqt?.authed || false);
            setAttraqtTenant(response.data.data.attraqt?.tenant || null);
            setShop(response.data.data.shop);
            setDebugMenuAccess(response.data.data.featureFlags?.debugMenuAccess || false);
            setQuickSearchEnabled(response.data.data.featureFlags?.quickSearchEnabled || false);
            setFullSearchEnabled(response.data.data.featureFlags?.fullSearchEnabled || false);
        }).catch(() => {
            setLoggedIn(false);
            setAttraqtAuthed(false);
            setAttraqtTenant(null);
        }).finally(() => {
            setRefresh(false);
        })
    }, [refresh])

    return <AuthContext.Provider value={state}>
        {children}
    </AuthContext.Provider>;
}

export {
    AuthProvider,
    AuthContext
};