import { Flex, FlexItem, Text } from "@bigcommerce/big-design";
import { theme } from "@bigcommerce/big-design-theme";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

type PageHeaderProps = {
  backlink?: string;
};

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const auth = useContext(AuthContext);
  const backComponent = (
    <FlexItem>
      {props.backlink && <Link to={props.backlink || "/"}>Back</Link>}
    </FlexItem>
  );
  const debugMenuitem = (
    <FlexItem marginLeft="medium" style={{ paddingTop: "2px" }}>
      <Link to="/debug" style={{ color: theme.colors.secondary60 }}>
        Debug
      </Link>
    </FlexItem>
  );

  return (
    <>
      <Flex
        justifyContent="space-between"
        flexDirection="row"
        marginTop="large"
        marginBottom="large"
      >
        {backComponent}
        <FlexItem marginHorizontal="small">
          <Flex flexDirection="row" justifyContent="flex-end">
            {auth.attraqt.tenant && (
              <FlexItem marginHorizontal="medium">
                <Text>Attraqt Account: {auth.attraqt.tenant}</Text>
              </FlexItem>
            )}
            <FlexItem marginHorizontal="medium">
              <Text>Store: {auth.shop?.name}</Text>
            </FlexItem>
            {auth.featureFlags?.debugMenuAccess && debugMenuitem}
            {/* <FlexItem marginLeft="medium" style={{ paddingTop: "3px" }}>
              <Link
                to="/refreshStore"
                style={{ color: theme.colors.secondary60 }}
              >
                Store Info
              </Link>
            </FlexItem> */}
            <FlexItem marginLeft="medium" style={{ paddingTop: "2px" }}>
              <Link to="/help" style={{ color: theme.colors.secondary60 }}>
                Help
              </Link>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    </>
  );
};

PageHeader.propTypes = {
  backlink: PropTypes.string,
};

export default PageHeader;
