import { Button, Table } from "@bigcommerce/big-design";
import { useState } from "react";
import { SortOption } from "../../../../../src/lib/app/db/sortOptions";
import { SortOptionForm, SortOptionFormProps } from "./SortOptionForm";
import { DeleteSortOption, DeleteSortOptionProps } from "./DeleteSortOption";

export type ViewSortOptionProps = {
  processing: boolean,
  sortOptions: SortOption[],
  handleReloadSortOptions: () => void,
  updateSortOptions: (sortOptions: SortOption[]) => void,
};

export const ViewSortOption = (props: ViewSortOptionProps): JSX.Element => {
  const [isOptionEditOpen, setIsOptionEditOpen] = useState(false);
  const [optionToEditId, setOptionToEditId] = useState<string>("");
  const [optionDeleteOpen, setOptionDeleteOpen] = useState(false);
  const [optionToDeleteId, setOptionToDeleteId] = useState<string>("");
  const [optionToDeleteSortOrder, setOptionToDeleteSortOrder] = useState<number>(0);
  const [label, setLabel] = useState<string>("");
  const [attribute, setAttribute] = useState<string>("");
  const [direction, setDirection] = useState<"asc" | "desc">("asc");
  const [sortOrder, setSortOrder] = useState<number>(0);

  const onSortOptionsTableDragEnd = (from: number, to: number) => {
    moveSortOption(props.sortOptions, from, to);
    props.updateSortOptions(props.sortOptions.map((sortOption, index) => {
      sortOption.sortOrder = index;
      return sortOption;
    }));
  }

  const moveSortOption = (sortOptions: SortOption[], from: number, to: number) => {
    sortOptions.splice(to, 0, sortOptions.splice(from, 1)[0]);
  }

  const handleLabelChange = (label: string) => {
    setLabel(label);
  }

  const handleAttributeChange = (attribute: string) => {
    setAttribute(attribute);
  }

  const handleDirectionChange = (direction: "asc" | "desc") => {
    setDirection(direction);
  }

  const handleReloadSortOptions = () => {
    props.handleReloadSortOptions();
  }

  const handleResetSortOptions = () => {
    moveSortOption(props.sortOptions, optionToDeleteSortOrder, (props.sortOptions.length - 1));
    props.sortOptions.pop();
    props.updateSortOptions(props.sortOptions.map((sortOption, index) => {
      sortOption.sortOrder = index;
      return sortOption;
    }));
  }

  const handleOptionClick = (optionData: Partial<SortOption>) => {
    setOptionToEditId(optionData.id ?? "");
    setLabel(optionData.label ?? "");
    setAttribute(optionData.attribute ?? "");
    setDirection(optionData.direction ?? "asc");
    setSortOrder(optionData.sortOrder ?? 0);
    setIsOptionEditOpen(true);
  }

  const handleOptionEditClose = () => {
    setOptionToEditId("");
    setLabel("");
    setAttribute("");
    setDirection("asc");
    setSortOrder(0);
    setIsOptionEditOpen(false);
  }

  const handleDeleteClick = (id: string, sortOrder: number) => {
    setOptionToDeleteId(id);
    setOptionToDeleteSortOrder(sortOrder);
    setOptionDeleteOpen(true);
  }

  const handleOptionDeleteClose = () => {
    setOptionToDeleteId("");
    setOptionToDeleteSortOrder(0);
    setOptionDeleteOpen(false);
  }

  const sortOptionFormProps: SortOptionFormProps = {
    action: "Edit",
    isOpen: isOptionEditOpen,
    handleClose: handleOptionEditClose,
    handleLabelChange: handleLabelChange,
    handleAttributeChange: handleAttributeChange,
    handleDirectionChange: handleDirectionChange,
    handleReloadSortOptions: handleReloadSortOptions,
    apiMethod: "put",
    apiPath: `/api/sort-option/${optionToEditId}`,
    modalHeader: "Edit a sort option",
    sortOptionData: {
      attribute,
      label,
      direction,
      sortOrder,
    }
  }

  const deleteSortOptionProps: DeleteSortOptionProps = {
    isOpen: optionDeleteOpen,
    optionToDeleteId: optionToDeleteId,
    handleClose: handleOptionDeleteClose,
    handleResetSortOptions,
  }

  return <>
    <br />
    <Table
      columns={[
        {
          header: 'Sort Order',
          hash: 'sortOrder',
          render: ({ id, label, attribute, direction, sortOrder }) => <Button onClick={(e) => { e.preventDefault(); handleOptionClick({ id, label, attribute, direction, sortOrder }) }} variant="subtle" isLoading={props.processing}>{sortOrder}</Button>,
          align: "center",
        },
        {
          header: 'Label',
          hash: 'label',
          render: ({ id, label, attribute, direction, sortOrder }) => <Button onClick={(e) => { e.preventDefault(); handleOptionClick({ id, label, attribute, direction, sortOrder }) }} variant="subtle" isLoading={props.processing}>{label}</Button>,
          align: "center",
        },
        {
          header: 'Attribute',
          hash: 'attribute',
          render: ({ id, label, attribute, direction, sortOrder }) => <Button onClick={(e) => { e.preventDefault(); handleOptionClick({ id, label, attribute, direction, sortOrder }) }} variant="subtle" isLoading={props.processing}>{attribute}</Button>,
          align: "center",
        },
        {
          header: 'Direction',
          hash: 'direction',
          render: ({ id, label, attribute, direction, sortOrder }) => <Button onClick={(e) => { e.preventDefault(); handleOptionClick({ id, label, attribute, direction, sortOrder }) }} variant="subtle" isLoading={props.processing}>{direction}</Button>,
          align: "center",
        },
        {
          header: 'Action',
          hash: 'action',
          render: ({ id, sortOrder }) => <Button onClick={(e) => { e.preventDefault(); handleDeleteClick(id || "", sortOrder) }} variant="secondary" isLoading={props.processing}>Delete</Button>,
          align: "center",
        },
      ]}
      items={props.sortOptions}
      onRowDrop={onSortOptionsTableDragEnd}
      stickyHeader
    />
    <br />
    <SortOptionForm {...sortOptionFormProps} />
    <DeleteSortOption {...deleteSortOptionProps} />
  </>;
}
