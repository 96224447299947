import { Flex, Input, Modal, Select, Small, Text, Link } from "@bigcommerce/big-design";
import { useState, useCallback, useEffect } from "react";
import { SortOption } from "../../../../../src/lib/app/db/sortOptions";
import { SortableAttributes } from "../../../../../src/lib/app/attraqt";

export type SortOptionFormProps = {
  action: string,
  isOpen: boolean,
  handleClose: () => void,
  apiMethod: string,
  apiPath: string,
  modalHeader: string,
  sortOptionData: SortOptionData,
  handleLabelChange: (label: string) => void,
  handleAttributeChange: (attribute: string) => void,
  handleDirectionChange: (direction: "asc" | "desc") => void,
  handleReloadSortOptions: () => void,
};

type SortOptionData = Omit<SortOption, "storeHash">;

export const SortOptionForm = (props: SortOptionFormProps): JSX.Element => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [sortableAttributes, setSortableAttributes] = useState<SortableAttributes>([]);
  const [searchConfId, setSearchConfId] = useState<string>("#");

  const setSortableAttributesData = () => {
    fetch('api/sortable-attributes').then((res) => res.json()).then((res) => {
      setProcessing(true);
      const attributes = res?.sortableAttributes ?? [];
      setSortableAttributes(attributes);
      if (attributes.length !== 0) {
        setSearchConfId(attributes[0].searchConfId);
      }
      setProcessing(false);
    }).finally(() => {
      setProcessing(false);
    })
  };

  useEffect(setSortableAttributesData, []);

  const handleDirectionChange = (value: "asc" | "desc") => {
    props.handleDirectionChange(value);
  }

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    props.handleLabelChange(target.value);
  }

  const handleAttributeChange = (value: string) => {
    props.handleAttributeChange(value);
  }

  const handleSortOptionModalCancel = useCallback(() => {
    props.handleClose();
  }, [props]);

  const handleSortOptionFormSubmit = useCallback(async () => {
    try {
      setProcessing(true);
      await fetch(props.apiPath, {
        method: props.apiMethod,
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ ...props.sortOptionData })
      });
      await fetch("/api/debug/publishScripts", {
        method: "post"
      });
      handleSortOptionModalCancel();
      props.handleReloadSortOptions();
    } finally {
      setProcessing(false);
    }
  }, [props, handleSortOptionModalCancel]);

  const sortableAttributesDescription = "Please select which attribute you want users to sort your products by on the product listing page (for example, by Price, Alphabetical, Most Popular). ";
  const sortableAttributesUrl = `https://console.early-birds.io/#/search/${searchConfId}/edit/sortable`;

  const sortOptionForm =
    <>
      <p>
        <Input
          label="Label"
          type="text"
          description="This label will be used internally to describe what sort options you’d like to add to your product listing page."
          required
          placeholder="Please enter the sort option label"
          value={props.sortOptionData.label}
          onChange={handleLabelChange}
          disabled={processing}
        />
      </p>
      <p>
        <Flex marginVertical="small"></Flex>
        <Flex><Text bold>Attribute</Text></Flex>
        <Flex marginVertical="xxSmall">
          <Small>
            {sortableAttributesDescription}
            <Link style={{ fontSize: "14px" }} href={sortableAttributesUrl}>
              <u>Click here to manage your attributes.</u>
            </Link>
          </Small>
        </Flex>
        <p>
          <Select
            filterable={true}
            maxHeight={200}
            onOptionChange={handleAttributeChange}
            options={sortableAttributes.map(sortableAttribute => {
              return { value: sortableAttribute.attribute, content: sortableAttribute.attribute };
            })}
            placeholder={'Please choose an attribute for your sort option'}
            placement={'bottom-start'}
            required={true}
            value={props.sortOptionData.attribute}
            disabled={processing}
          />
        </p>
      </p>
      <p>
        <Flex marginVertical="small"></Flex>
        <Select
          filterable={true}
          label="Direction"
          maxHeight={100}
          onOptionChange={handleDirectionChange}
          options={[
            { value: 'asc', content: 'Ascending' },
            { value: 'desc', content: 'Descending' },
          ]}
          placement={'bottom-start'}
          required={true}
          value={props.sortOptionData.direction}
          disabled={processing}
        />
      </p>
    </>

  return <>
    <br />
    <Modal
      actions={[
        { text: 'Cancel', variant: 'subtle', onClick: handleSortOptionModalCancel },
        { text: props.action, onClick: handleSortOptionFormSubmit },
      ]}
      header={props.modalHeader}
      isOpen={props.isOpen}
      onClose={props.handleClose}
      closeOnEscKey={true}
      closeOnClickOutside={false}
    >
      {sortOptionForm}
    </Modal>
  </>;
}
