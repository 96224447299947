import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import { H2, H3, Text, Flex, FlexItem, Button, Link } from "@bigcommerce/big-design"

type HelpProps = {};

const Help = (props: HelpProps): JSX.Element => {
    return <>
        <PageHeader backlink="/" />
        <H2 marginTop="large">Help</H2>
        <Flex flexDirection="row" flexWrap="nowrap" justifyContent="center" marginTop="large">
            <FlexItem paddingHorizontal="medium" flexGrow={1} style={{ width: "33%", textAlign: "center" }}>
                <H3>User Guide</H3>
                <Text>This guide details how to use the app effectively and outlines a few key use cases to get you started.</Text>
                <Link href="https://support.attraqt.com/hc/en-gb/articles/4404465604498" target="_blank" external>
                    <Button>Open</Button>
                </Link>
            </FlexItem>
            <FlexItem paddingHorizontal="medium" flexGrow={1} style={{ width: "33%", textAlign: "center" }}>
                <H3>Frequently Asked Questions</H3>
                <Text>For more information, or to find the answers to frequently asked questions please consult our FAQs.</Text>
                <Link href="https://support.attraqt.com/hc/en-gb/articles/4404458651538-FAQs-Attraqt-App-for-BigCommerce" target="_blank" external>
                    <Button>Open</Button>
                </Link>
            </FlexItem>
            <FlexItem paddingHorizontal="medium" flexGrow={1} style={{ width: "33%", textAlign: "center" }}>
                <H3>Support</H3>
                <Text>If you’re having any trouble with the app, please reach out to our Support team.</Text>
                <Link href="https://support.attraqt.com" target="_blank" external>
                    <Button style={{ margin: "auto" }}>Open</Button>
                </Link>
            </FlexItem>
        </Flex>
        <PageFooter />
    </>;
}

export default Help;