import { Button } from "@bigcommerce/big-design";
import { useState } from "react";
import { WidgetTemplateForm, WidgetTemplateFormProps } from "./WidgetTemplateForm";

export type CreateWidgetTemplateProps = {
  storeHash: string,
  handleReloadTemplates: () => void,
};

export const CreateWidgetTemplate = (props: CreateWidgetTemplateProps): JSX.Element => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [templateData, setTemplateData] = useState<string>("");
  const [isDeveloperTemplate, setIsDeveloperTemplate] = useState<boolean>(false);
  const [templateCode, setTemplateCode] = useState<string>("");

  const handleTemplateNameChange = (templateName: string) => {
    setTemplateName(templateName);
  }

  const handleTemplateDataChange = (templateData: string) => {
    setTemplateData(templateData);
  }

  const handleTemplateCodeChange = (templateCode: string) => {
    setTemplateCode(templateCode);
  }

  const handleIsDeveloperTemplateChange = () => {
    if (isDeveloperTemplate) {
      setTemplateCode("");
    } else {
      setTemplateData("");
    }
    setIsDeveloperTemplate(!isDeveloperTemplate);
  }

  const HandleTemplateAddClick = () => {
    setIsFormOpen(true);
  }

  const handleTemplateFormClose = () => {
    setTemplateName("");
    setTemplateData("");
    setTemplateCode("");
    setIsDeveloperTemplate(false);
    setIsFormOpen(false);
  }

  const handleReloadTemplates = () => {
    props.handleReloadTemplates();
  }

  const widgetTemplateFormProps: WidgetTemplateFormProps = {
    action: "Create",
    isOpen: isFormOpen,
    storeHash: props.storeHash,
    handleClose: handleTemplateFormClose,
    handleTemplateNameChange,
    handleTemplateDataChange,
    handleTemplateCodeChange,
    handleIsDeveloperTemplateChange,
    handleReloadTemplates,
    apiMethod: "post",
    apiPath: "api/widget-template",
    modalHeader: "Create a Widget Template",
    widgetTemplateData: {
      isDeveloperTemplate,
      templateName,
      templateData,
      templateCode,
    }
  }

  return <>
    <Button onClick={HandleTemplateAddClick}>Add Widget Template</Button>
    <br />
    <WidgetTemplateForm {...widgetTemplateFormProps} />
  </>;
}
