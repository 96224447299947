import { Modal, Text } from "@bigcommerce/big-design";
import { useCallback } from "react";

export type DeleteSortOptionProps = {
  isOpen: boolean,
  optionToDeleteId: string,
  handleClose: () => void,
  handleResetSortOptions: () => void,
};

export const DeleteSortOption = (props: DeleteSortOptionProps): JSX.Element => {
  const handleDeleteCancel = useCallback(() => {
    props.handleClose();
  }, [props]);

  const handleDeleteConfirmation = useCallback(async () => {
    try {
      await fetch(`/api/sort-option/${props.optionToDeleteId}`, {
        method: 'delete'
      });
      await fetch("/api/debug/publishScripts", {
        method: "post"
      });
      handleDeleteCancel();
      props.handleResetSortOptions();
    } finally { }
  }, [props, handleDeleteCancel]);

  return <>
    <Modal
      actions={[
        { text: 'Cancel', variant: 'subtle', onClick: handleDeleteCancel },
        { text: 'Delete', onClick: handleDeleteConfirmation },
      ]}
      header="Delete your Custom Sort Option"
      isOpen={props.isOpen}
      onClose={props.handleClose}
      closeOnEscKey={true}
      closeOnClickOutside={false}
    >
      <Text>
        Are you sure you want to delete the sort option?
      </Text>
    </Modal>
  </>
}
