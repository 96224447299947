import { Input, Modal, Select, Switch as BCSwitch, Flex, FlexItem, Tooltip, Link, Text, Small } from "@bigcommerce/big-design";
import { InfoIcon } from "@bigcommerce/big-design-icons";
import { useState, useCallback, useEffect } from "react";
import { FacetConfiguration } from "../../../../../src/lib/app/db/facetConfigurations";
import { FacetableAttributes } from "../../../../../src/lib/app/attraqt";

export type FacetConfigurationFormProps = {
  action: string,
  isOpen: boolean,
  handleClose: () => void,
  apiMethod: string,
  apiPath: string,
  modalHeader: string,
  facetConfigurationData: FacetConfigurationData,
  handleAttributeChange: (attribute: string) => void,
  handleUiChange: (label: string) => void,
  handleIsFilterFacetChange: () => void,
  handleHasCustomUiChange: () => void,
  handleReloadFacetConfigurations: () => void,
};

type FacetConfigurationData = Omit<FacetConfiguration, "storeHash">;

export const FacetConfigurationForm = (props: FacetConfigurationFormProps): JSX.Element => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [facetableAttributes, setFacetableAttributes] = useState<FacetableAttributes[]>([]);
  const [searchConfId, setSearchConfId] = useState<string>("#");

  const setFacetableAttributesData = () => {
    fetch('api/facetable-attributes').then((res) => res.json()).then((res) => {
      setProcessing(true);
      const attributes = res?.facetableAttributes ?? [];
      setFacetableAttributes(attributes);
      if (attributes.length !== 0) {
        setSearchConfId(attributes[0].searchConfId);
      }
      setProcessing(false);
    }).finally(() => {
      setProcessing(false);
    })
  };

  useEffect(setFacetableAttributesData, []);

  const handleAttributeChange = (value: string) => {
    props.handleAttributeChange(value);
  }

  const handleUiChange = (value: string) => {
    if (value === "custom") {
      props.handleHasCustomUiChange();
      props.handleUiChange("");
    } else {
      if (props.facetConfigurationData.hasCustomUi) {
        props.handleHasCustomUiChange();
      }
      props.handleUiChange(value);
    }
  }

  const handleIsFilterFacetChange = () => {
    props.handleIsFilterFacetChange();
  }

  const handleCustomUiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    props.handleUiChange(target.value);
  }

  const handleFacetConfigurationModalCancel = useCallback(() => {
    props.handleClose();
  }, [props]);

  const handleFacetConfigurationFormSubmit = useCallback(async () => {
    try {
      setProcessing(true);
      await fetch(props.apiPath, {
        method: props.apiMethod,
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ ...props.facetConfigurationData })
      });
      await fetch("/api/debug/publishScripts", {
        method: "post"
      });
      handleFacetConfigurationModalCancel();
      props.handleReloadFacetConfigurations();
    } finally {
      setProcessing(false);
    }
  }, [props, handleFacetConfigurationModalCancel]);

  const facetableAttributesDescription = "Please select which attribute you want users to sort your products by on the product listing page (for example, by Price, Alphabetical, Most Popular). ";
  const facetableAttributesUrl = `https://console.early-birds.io/#/search/${searchConfId}/edit/faceting`;
  const uiDescription = `Please map the attribute above to a facet user interface so that it can be presented to the customer on the front-end.` +
    ` This is an option between standard multi-select facets (e.g. for product colour), searchable facets (e.g. for a long list of brands), or a range facet (e.g. for price).`;
  const customUiDescription = "Please add your own customisation to the UI if you’d like to alter the appearance or completely replace the front-end look and feel.";

  const customUiInput = props.facetConfigurationData.hasCustomUi ?
    <p>
      <Flex marginVertical="small"></Flex>
      <Input
        label="Custom UI"
        description={customUiDescription}
        type="text"
        required
        placeholder="Please enter your custom UI"
        value={props.facetConfigurationData.hasCustomUi ? props.facetConfigurationData.ui : ""}
        onChange={handleCustomUiChange}
      />
    </p> : <></>;

  const facetConfigurationForm = (
    <>
      <p>
        <Flex marginVertical="small"></Flex>
        <Flex>
          <Text bold>Attribute</Text>
        </Flex>
        <Flex marginVertical="xxSmall">
          <Small>
            {facetableAttributesDescription}
            <Link style={{ fontSize: "14px" }} href={facetableAttributesUrl}>
              <u>Click here to manage your attributes.</u>
            </Link>
          </Small>
        </Flex>
        <p>
          <Select
            filterable={true}
            maxHeight={200}
            onOptionChange={handleAttributeChange}
            options={facetableAttributes.map((facetableAttribute) => {
              return {
                value: facetableAttribute.attribute,
                content: facetableAttribute.attribute,
              };
            })}
            placeholder={
              "Please choose an attribute for your facet configuration"
            }
            placement={"bottom-start"}
            required
            value={props.facetConfigurationData.attribute}
            disabled={processing}
          />
        </p>
      </p>
      <p>
        <Flex marginVertical="small"></Flex>
        <Select
          filterable={true}
          label="UI (User Interface)"
          description={uiDescription}
          maxHeight={200}
          onOptionChange={handleUiChange}
          options={[
            { value: "basic", content: "Basic" },
            { value: "datalist", content: "Datalist" },
            { value: "numeric_range", content: "Numeric_Range" },
            { value: "custom", content: "Custom" },
          ]}
          placeholder={"Please choose a UI for your facet configuration"}
          placement={"bottom-start"}
          required
          value={
            !props.facetConfigurationData.hasCustomUi
              ? props.facetConfigurationData.ui
              : "custom"
          }
          disabled={processing}
        />
      </p>
      <Flex>
        <FlexItem marginRight="small" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <BCSwitch
            checked={props.facetConfigurationData.isFilterFacet}
            onChange={handleIsFilterFacetChange}
            disabled={processing}
          />
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem marginRight="xxSmall" style={{ paddingTop: "4px" }}>
              <Text>Filtered Facet?</Text>
            </FlexItem>
            <FlexItem style={{ paddingTop: "6px" }}>
              <Tooltip
                trigger={<InfoIcon color="secondary50" size="large" />}
                placement="right"
              >
                <Small>
                  Please turn on this toggle if you require a filtered facet (numeric_range)
                </Small>
              </Tooltip>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
      {customUiInput}
    </>
  );

  return <>
    <br />
    <Modal
      actions={[
        { text: 'Cancel', variant: 'subtle', onClick: handleFacetConfigurationModalCancel },
        { text: props.action, onClick: handleFacetConfigurationFormSubmit },
      ]}
      header={props.modalHeader}
      isOpen={props.isOpen}
      onClose={props.handleClose}
      closeOnEscKey={true}
      closeOnClickOutside={false}
    >
      {facetConfigurationForm}
    </Modal>
  </>;
}
