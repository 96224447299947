import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import {useLocation} from "react-router-dom";

type NotFoundProps = {};

const NotFound = (props: NotFoundProps): JSX.Element => {
    let location = useLocation();

    return <>
        <PageHeader />
        <p>404: Page Not Found (<code>{location.pathname}</code>)</p>
        <PageFooter />
    </>;
}

export default NotFound;