import {H0, Text} from "@bigcommerce/big-design"

const AuthReturn = () => {
    // If we've got an opener set (which was our triggering page) then reload
    // the page and close this one.
    if (window.opener) {
        window.opener.location.reload();
        window.close();
    }

    return <>
        <H0 marginTop="xxxLarge">Account linked!</H0>
        <Text>Your Attraqt account has been linked to your BigCommerce store. Head back to your admin interface and refresh the app to continue.</Text>
    </>;
}

export default AuthReturn;