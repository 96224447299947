import { Modal, Text } from "@bigcommerce/big-design";
import { useCallback } from "react";

export type DeleteWidgetTemplateProps = {
  isOpen: boolean,
  templateToDeleteId: string,
  handleClose: () => void,
  handleReloadTemplates: () => void,
};

export const DeleteWidgetTemplate = (props: DeleteWidgetTemplateProps): JSX.Element => {
  const handleDeleteCancel = useCallback(() => {
    props.handleClose();
  }, [props]);

  const handleDeleteConfirmation = useCallback(async () => {
    try {
      await fetch(`/api/widget-template/${props.templateToDeleteId}`, {
        method: 'delete'
      });
      await fetch("/api/debug/addWidgetToPageBuilder", {
        method: "post"
      });
      await fetch("/api/debug/publishScripts", {
        method: "post"
      });
      props.handleReloadTemplates();
      handleDeleteCancel();
    } finally { }
  }, [props, handleDeleteCancel]);

  return <>
    <Modal
      actions={[
        { text: 'Cancel', variant: 'subtle', onClick: handleDeleteCancel },
        { text: 'Delete', onClick: handleDeleteConfirmation },
      ]}
      header="Delete your Custom Widget Template"
      isOpen={props.isOpen}
      onClose={props.handleClose}
      closeOnEscKey={true}
      closeOnClickOutside={false}
    >
      <Text>
        Are you sure you want to delete the widget template?
      </Text>
    </Modal>
  </>
}
