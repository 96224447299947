type LogOutputProps = {
    title: string,
    output: string | null;
    styleWhenVisible?: React.CSSProperties;
};

const LogOutput = (props: LogOutputProps): JSX.Element => {
    if (props.output) {
        return <div style={props.styleWhenVisible}>
            <strong>{ props.title }</strong>
            <pre style={{overflow: "scroll", maxHeight: "250px", background: "black", color: "lightgreen", padding: "10px"}}>{ JSON.stringify(props.output, null, 2) }</pre>
        </div>
    }

    return <></>;
};

export default LogOutput;