import { Button, Table } from "@bigcommerce/big-design";
import { useState } from "react";
import { DeleteWidgetTemplate, DeleteWidgetTemplateProps } from "./DeleteWidgetTemplate";
import { WidgetTemplate } from "../../../../../src/lib/app/db/widgetTemplates";
import { WidgetTemplateForm, WidgetTemplateFormProps } from "./WidgetTemplateForm";

export type ViewWidgetTemplateProps = {
  loading: boolean,
  storeHash: string,
  widgetTemplates: WidgetTemplate[],
  handleReloadTemplates: () => void,
};

export const ViewWidgetTemplate = (props: ViewWidgetTemplateProps): JSX.Element => {
  const [templateDeleteOpen, setTemplateDeleteOpen] = useState(false);
  const [isTemplateEditOpen, setIsTemplateEditOpen] = useState(false);
  const [templateToDeleteId, setTemplateToDeleteId] = useState<string>("");
  const [templateToEditId, setTemplateToEditId] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [templateData, setTemplateData] = useState<string>("");
  const [isDeveloperTemplate, setIsDeveloperTemplate] = useState<boolean>(false);
  const [templateCode, setTemplateCode] = useState<string>("");

  const handleTemplateClick = (templateData: Partial<WidgetTemplate>) => {
    setTemplateToEditId(templateData.id ?? "");
    setIsDeveloperTemplate(templateData.isDeveloperTemplate ?? false);
    setTemplateName(templateData.templateName ?? "");
    setTemplateData(templateData.templateData ?? "");
    setTemplateCode(templateData.templateCode ?? "");
    setIsTemplateEditOpen(true);
  }

  const handleTemplateEditClose = () => {
    setTemplateName("");
    setTemplateCode("");
    setTemplateData("");
    setIsDeveloperTemplate(false);
    setIsTemplateEditOpen(false);
  }

  const handleTemplateDeleteClose = () => {
    setTemplateToDeleteId("");
    setTemplateDeleteOpen(false);
  }

  const handleDeleteClick = (id: string) => {
    setTemplateToDeleteId(id);
    setTemplateDeleteOpen(true);
  }

  const handleTemplateNameChange = (templateName: string) => {
    setTemplateName(templateName);
  }

  const handleTemplateDataChange = (templateData: string) => {
    setTemplateData(templateData);
  }

  const handleTemplateCodeChange = (templateCode: string) => {
    setTemplateCode(templateCode);
  }

  const handleIsDeveloperTemplateChange = () => {
    if (isDeveloperTemplate) {
      setTemplateCode("");
    } else {
      setTemplateData("");
    }
    setIsDeveloperTemplate(!isDeveloperTemplate);
  }

  const handleReloadTemplates = () => {
    props.handleReloadTemplates();
  }

  const widgetTemplateFormProps: WidgetTemplateFormProps = {
    action: "Edit",
    isOpen: isTemplateEditOpen,
    storeHash: props.storeHash,
    handleClose: handleTemplateEditClose,
    handleTemplateNameChange,
    handleTemplateDataChange,
    handleTemplateCodeChange,
    handleIsDeveloperTemplateChange,
    handleReloadTemplates,
    apiMethod: "put",
    apiPath: `/api/widget-template/${templateToEditId}`,
    modalHeader: "Edit a Widget Template",
    widgetTemplateData: {
      isDeveloperTemplate,
      templateName,
      templateData,
      templateCode,
    }
  }

  const deleteWidgetTemplateProps: DeleteWidgetTemplateProps = {
    isOpen: templateDeleteOpen,
    templateToDeleteId: templateToDeleteId,
    handleClose: handleTemplateDeleteClose,
    handleReloadTemplates,
  }

  return <>
    <br />
    <Table
      columns={[
        {
          header: 'Template name',
          hash: 'templateName',
          render: ({ id, isDeveloperTemplate, templateName, templateData, templateCode }) => <Button onClick={(e) => { e.preventDefault(); handleTemplateClick({ id, isDeveloperTemplate, templateName, templateData, templateCode }) }} variant="subtle" isLoading={props.loading}>{templateName}</Button>,
          align: "left",
        },
        {
          header: 'Template name',
          hideHeader: true,
          hash: 'templateName',
          render: () => <></>,
          align: "center",
        },
        {
          header: 'Template name',
          hideHeader: true,
          hash: 'templateName',
          render: () => <></>,
          align: "center",
        },
        {
          header: 'Template name',
          hideHeader: true,
          hash: 'templateName',
          render: () => <></>,
          align: "center",
        },
        {
          header: 'Template name',
          hideHeader: true,
          hash: 'templateName',
          render: () => <></>,
          align: "center",
        },
        {
          header: 'Template name',
          hideHeader: true,
          hash: 'templateName',
          render: () => <></>,
          align: "center",
        },
        {
          header: 'Action',
          hash: 'action',
          render: ({ id }) => <Button onClick={(e) => { e.preventDefault(); handleDeleteClick(id || "") }} variant="secondary" isLoading={props.loading}>Delete</Button>,
          align: "center",
        },
      ]}
      items={props.widgetTemplates}
      stickyHeader
    />
    <br />
    <DeleteWidgetTemplate {...deleteWidgetTemplateProps} />
    <WidgetTemplateForm {...widgetTemplateFormProps} />
  </>;
}

export default ViewWidgetTemplate;
