import { CreateWidgetTemplate, CreateWidgetTemplateProps } from "./CreateWidgetTemplate";
import { WidgetTemplate } from "../../../../../src/lib/app/db/widgetTemplates";
import { ViewWidgetTemplate, ViewWidgetTemplateProps } from "./ViewWidgetTemplate";
import { useState, useEffect, useCallback } from "react";
import { H2, Small } from "@bigcommerce/big-design";

type CustomWidgetTemplateProps = {};

const CustomWidgetTemplate = (props: CustomWidgetTemplateProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [widgetTemplates, setWidgetTemplates] = useState<Array<WidgetTemplate>>([]);
  const [storeHash, setStoreHash] = useState<string>("");

  const setTemplateTableData = () => {
    fetch('api/widget-templates').then((res) => res.json()).then((res) => {
      setLoading(true);
      setWidgetTemplates(res?.data?.widgetTemplates);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    })
  };

  const setCurrentStoreHash = () => {
    fetch('api/config').then((res) => res.json()).then((res) => {
      setLoading(true);
      setStoreHash(res?.storeHash);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    })
  };

  useEffect(setTemplateTableData, []);
  useEffect(setCurrentStoreHash, []);

  const handleReloadTemplates = useCallback(setTemplateTableData, []);

  const viewWidgetTemplateProps: ViewWidgetTemplateProps = {
    loading,
    storeHash,
    widgetTemplates,
    handleReloadTemplates,
  }

  const createWidgetTemplateProps: CreateWidgetTemplateProps = {
    storeHash,
    handleReloadTemplates,
  }

  return <>
    <br />
    <H2 marginVertical="large">Widget Template</H2>
    <Small>Define how you want your widgets to appear on the front-end. Please ensure your widget template is selected when adding a recommendation widget in page builder.</Small>
    <ViewWidgetTemplate {...viewWidgetTemplateProps} />
    <CreateWidgetTemplate {...createWidgetTemplateProps} />
  </>;
}

export default CustomWidgetTemplate;
