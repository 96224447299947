import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import { H2, Text, Panel, Tabs } from "@bigcommerce/big-design";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";


const StoreGeneralInfo = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [storeData, setStoreData] = useState<any | null>(null);

    const getAndRefreshStore = useCallback(() => {
        setIsLoading(true);
        axios.get("/api/debug/refreshStore").then((result) => {
            setStoreData(result.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        getAndRefreshStore();
    }, [getAndRefreshStore]);

    return <>
        <Text bold>Store Info</Text>
        {
            (isLoading) ?
                <pre style={{ border: "1px solid black", padding: "10px", boxSizing: "border-box", width: "100%" }}>Loading data from BC and storing it to Firestore. Please wait...</pre>
                :
                <pre style={{ border: "1px solid black", padding: "10px", boxSizing: "border-box", width: "100%" }}>{JSON.stringify(storeData, null, 2)}</pre>
        }
    </>;
}

const RegisteredWebhooks = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [webhooksData, setWebhooksData] = useState<any | null>(null);

    const getAllRegisteredWebhooks = useCallback(() => {
        setIsLoading(true);
        axios.get("/api/store/getAllRegisteredWebhooks").then((result) => {
            setWebhooksData(result.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        getAllRegisteredWebhooks();
    }, [getAllRegisteredWebhooks]);

    return <>
        <Text bold>Registered Webhooks</Text>
        {
            (isLoading) ?
                <pre style={{ border: "1px solid black", padding: "10px", boxSizing: "border-box", width: "100%" }}>Loading data from BC and storing it to Firestore. Please wait...</pre>
                :
                <pre style={{ border: "1px solid black", padding: "10px", boxSizing: "border-box", width: "100%" }}>{JSON.stringify(webhooksData, null, 2)}</pre>
        }
    </>;
}

const StoreInfo = (): JSX.Element => {
    const [activeTab, setActiveTab] = useState('tab1');
    const items = [
        { id: 'tab1', title: 'General info' },
        { id: 'tab2', title: 'Registered webhooks' },
    ];
    return <>
        <PageHeader backlink="/" />
        <Panel>
            <Tabs activeTab={activeTab} items={items} onTabClick={setActiveTab} />
            {activeTab === 'tab1' &&
                <>
                    <H2 marginTop="large">Refresh store catalog meta, summary and currencies.</H2>
                    <Text>This page will display and refresh store info by pulling it from BC and storing it to Firestore.</Text>
                    <StoreGeneralInfo />
                </>
            }
            {activeTab === 'tab2' &&
                <>
                    <H2 marginTop="large">Get and refresh store registered webhooks.</H2>
                    <Text>This page will display all the refresh webhooks for the store by pulling it from BC and storing it to Firestore.</Text>
                    <RegisteredWebhooks />
                </>
            }
        </Panel>
        <PageFooter />
    </>;
}
export default StoreInfo;