import { Button } from "@bigcommerce/big-design";
import { useState } from "react";
import { FacetConfiguration } from "../../../../../src/lib/app/db/facetConfigurations";
import { FacetConfigurationForm, FacetConfigurationFormProps } from "./FacetConfigurationForm";

export type CreateFacetConfigurationProps = {
  facetConfigurations: FacetConfiguration[],
  handleReloadFacetConfigurations: () => void,
};

export const CreateFacetConfiguration = (props: CreateFacetConfigurationProps): JSX.Element => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [attribute, setAttribute] = useState<string>("");
  const [ui, setUi] = useState<string>("");
  const [isFilterFacet, setIsFilterFacet] = useState<boolean>(false);
  const [hasCustomUi, setHasCustomUi] = useState<boolean>(false);

  const handleAttributeChange = (attribute: string) => {
    setAttribute(attribute);
  }

  const handleUiChange = (ui: string) => {
    setUi(ui);
  }

  const handleIsFilterFacetChange = () => {
    setIsFilterFacet(!isFilterFacet);
  }

  const handleHasCustomUiChange = () => {
    setHasCustomUi(!hasCustomUi);
  }

  const HandleFacetConfigurationAddClick = () => {
    setIsFormOpen(true);
  }

  const handleFacetConfigurationFormClose = () => {
    setAttribute("");
    setUi("");
    setHasCustomUi(false);
    setIsFilterFacet(false);
    setIsFormOpen(false);
  }

  const handleReloadFacetConfigurations = () => {
    props.handleReloadFacetConfigurations();
  }

  const facetConfigurationFormProps: FacetConfigurationFormProps = {
    action: "Create",
    isOpen: isFormOpen,
    handleClose: handleFacetConfigurationFormClose,
    handleAttributeChange,
    handleUiChange,
    handleIsFilterFacetChange,
    handleHasCustomUiChange,
    handleReloadFacetConfigurations,
    apiMethod: "post",
    apiPath: "api/facet-configuration",
    modalHeader: "Facet Configuration",
    facetConfigurationData: {
      attribute,
      ui,
      isFilterFacet,
      hasCustomUi,
      sortOrder: props.facetConfigurations.length !== 0 ? props.facetConfigurations.length : 0,
    }
  }

  return <>
    <Button onClick={HandleFacetConfigurationAddClick}>Add Facet Configuration</Button>
    <br />
    <FacetConfigurationForm {...facetConfigurationFormProps} />
  </>;
}
