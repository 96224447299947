import { spring, AnimatedSwitch } from 'react-router-transition';

// wrap the `spring` helper to use a bouncy config
const bounce = (val: any) => spring(val, {stiffness: 330, damping: 22});

const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {opacity: 0},
    // leave in a transparent, downscaled state
    atLeave: {opacity: bounce(0)},
    // and rest at an opaque, normally-scaled state
    atActive: {opacity: bounce(1)}
};

// we need to map the `scale` prop we define below
// to the transform style property
const mapStyles = (styles: any) => {
    return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
    };
}

type SwitchTransitionProps = {
    children?: JSX.Element[] | JSX.Element;
}

const SwitchTransition = ({children}: SwitchTransitionProps) => {
    return (
        <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"
        >
            {children}
        </AnimatedSwitch>
    );
}

export default SwitchTransition;