import { CreateFacetConfiguration, CreateFacetConfigurationProps } from "./CreateFacetConfiguration";
import { ViewFacetConfiguration, ViewFacetConfigurationProps } from "./ViewFacetConfiguration";
import { FacetConfiguration } from "../../../../../src/lib/app/db/facetConfigurations";
import { useState, useEffect, useCallback } from "react";
import { H2, Small } from "@bigcommerce/big-design";

type CustomFacetConfigurationProps = {};

const CustomFacetConfiguration = (props: CustomFacetConfigurationProps): JSX.Element => {
  const [processing, setProcessing] = useState<boolean>(true);
  const [facetConfigurations, setFacetConfigurations] = useState<Array<FacetConfiguration>>([]);

  const setConfigurationTableData = () => {
    fetch('api/facet-configurations').then((res) => res.json()).then((res) => {
      setProcessing(true);
      const facetConfigurations: FacetConfiguration[] = res?.data?.facetConfigurations;
      setFacetConfigurations(facetConfigurations.sort((configuration1: FacetConfiguration, configuration2: FacetConfiguration) => configuration1.sortOrder - configuration2.sortOrder));
      setProcessing(false);
    }).finally(() => {
      setProcessing(false);
    })
  };

  useEffect(setConfigurationTableData, []);

  const handleReloadFacetConfigurations = useCallback(setConfigurationTableData, []);

  const updateFacetConfigurations = async (facetConfigurations: FacetConfiguration[]) => {
    try {
      setProcessing(true);
      await Promise.all(facetConfigurations.map(async facetConfiguration => {
        return fetch(`/api/facet-configuration/${facetConfiguration.id}`, {
          method: 'put',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ ...facetConfiguration })
        }).then(response => response);
      }));
      await fetch("/api/debug/publishScripts", {
        method: "post"
      });
      handleReloadFacetConfigurations();
    } finally {
      setProcessing(false);
    }
  }

  const viewFacetConfigurationProps: ViewFacetConfigurationProps = {
    processing,
    facetConfigurations,
    handleReloadFacetConfigurations,
    updateFacetConfigurations,
  }

  const createFacetConfigurationProps: CreateFacetConfigurationProps = {
    facetConfigurations,
    handleReloadFacetConfigurations,
  }

  return <>
    <H2 marginVertical="large">Facet Configurations</H2>
    <Small>Select from a range of the facets that you would like front-end users to filter products by. (e.g. an admin would be able to define price as being a “range” attribute and would therefore present the user with a range selection interface on the frontend, instead of the typical multi-select UI).</Small>
    <ViewFacetConfiguration {...viewFacetConfigurationProps} />
    <CreateFacetConfiguration {...createFacetConfigurationProps} />
  </>;
}

export default CustomFacetConfiguration;
