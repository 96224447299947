import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import { H0, Text, Box, Flex, FlexItem, Button } from "@bigcommerce/big-design"
import { AuthContext } from "../context/AuthContext";
import { useContext, useState } from "react";
import TenantsSelector from "../components/Attraqt/TenantsSelector";
import axios from "axios";
import { useHistory } from "react-router-dom";

type OnboardingProps = {};

const Onboarding = (props: OnboardingProps): JSX.Element => {
    const { attraqt, refresh } = useContext(AuthContext);
    const [tenant, setTenant] = useState<any>(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const history = useHistory();

    const authed = attraqt.authed;
    const tenantSelected = attraqt.tenant !== null;

    const submitTenantSelection = () => {
        setSubmitDisabled(true);

        axios.post('/api/tenant', {
            tenantId: tenant?.id,
            tenantEnvName: tenant?.tenantEnvName,
            tenantName: tenant?.tenantName,
        }).then((response) => {
            refresh();
            history.push('/');
        }).finally(() => {
            setSubmitDisabled(false);
        })
    }

    const authStep = <>
        <H0 marginTop="xxxLarge">Welcome to the Attraqt App!</H0>
        <Text>You are mere moments away from accessing powerful, AI-driven, search, merchandising, and personalization solutions that will enable you to facilitate product discovery and orchestrate shopper moments, from inspiration all the way to re-engagement.</Text>
        <Text>We know you are itching to get started!</Text>
        <Text>But first, you’ll need to link your BigCommerce store with your Experience Orchestrator (XO) account. Simply click the link below, which will take you to the XO login screen. Once logged in, you’ll be automatically redirected to the app to complete your setup.</Text>
        <div style={{ textAlign: "center" }}>
            <Button onClick={() => { window.open('https://content.attraqt.com/bigcommerce', '_blank') }}>
                Contact Us
            </Button>
            <Button onClick={() => { window.open('/auth/start', '_blank', 'opener') }}>
                Login
            </Button>
        </div>
    </>;

    const tenantStep = <>
        <H0 marginTop="xxxLarge" style={{ textAlign: "center" }}>Which account should we use?</H0>
        <Flex style={{ width: "600px" }} justifyContent="space-between">
            <FlexItem flexGrow={100} paddingRight="small">
                <TenantsSelector style={{ width: "100%", padding: "6px" }} onSelect={setTenant} />
            </FlexItem>
            <FlexItem flexGrow={1} style={{ textAlign: "right" }}>
                <Button disabled={!tenant || submitDisabled} onClick={submitTenantSelection}>Continue</Button>
            </FlexItem>
        </Flex>
    </>;

    return <>
        <PageHeader />
        <Box style={{ maxWidth: "600px", margin: "auto" }}>
            {!authed ? authStep : ((!tenantSelected) ? tenantStep : '')}
        </Box>
        <PageFooter />
    </>;
}

export default Onboarding;