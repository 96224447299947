import { Button, Table } from "@bigcommerce/big-design";
import { useState } from "react";
import { FacetConfiguration } from "../../../../../src/lib/app/db/facetConfigurations";
import { FacetConfigurationForm, FacetConfigurationFormProps } from "./FacetConfigurationForm";
import { DeleteFacetConfiguration, DeleteFacetConfigurationProps } from "./DeleteFacetConfiguration";

export type ViewFacetConfigurationProps = {
  processing: boolean,
  facetConfigurations: FacetConfiguration[],
  handleReloadFacetConfigurations: () => void,
  updateFacetConfigurations: (facetConfigurations: FacetConfiguration[]) => void,
};

export const ViewFacetConfiguration = (props: ViewFacetConfigurationProps): JSX.Element => {
  const [isConfigurationEditOpen, setIsConfigurationEditOpen] = useState(false);
  const [configurationToEditId, setConfigurationToEditId] = useState<string>("");
  const [configurationDeleteOpen, setConfigurationDeleteOpen] = useState(false);
  const [configurationToDeleteId, setConfigurationToDeleteId] = useState<string>("");
  const [configurationToDeleteSortOrder, setConfigurationToDeleteSortOrder] = useState<number>(0);
  const [attribute, setAttribute] = useState<string>("");
  const [ui, setUi] = useState<string>("");
  const [isFilterFacet, setIsFilterFacet] = useState<boolean>(false);
  const [hasCustomUi, setHasCustomUi] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<number>(0);

  const onFacetConfigurationsTableDragEnd = (from: number, to: number) => {
    moveFacetConfiguration(props.facetConfigurations, from, to);
    props.updateFacetConfigurations(props.facetConfigurations.map((facetConfiguration, index) => {
      facetConfiguration.sortOrder = index;
      return facetConfiguration;
    }));
  }

  const moveFacetConfiguration = (facetConfigurations: FacetConfiguration[], from: number, to: number) => {
    facetConfigurations.splice(to, 0, facetConfigurations.splice(from, 1)[0]);
  }

  const handleAttributeChange = (attribute: string) => {
    setAttribute(attribute);
  }

  const handleUiChange = (ui: string) => {
    setUi(ui);
  }

  const handleIsFilterFacetChange = () => {
    setIsFilterFacet(!isFilterFacet);
  }

  const handleHasCustomUiChange = () => {
    setHasCustomUi(!hasCustomUi);
  }

  const handleReloadFacetConfigurations = () => {
    props.handleReloadFacetConfigurations();
  }

  const handleResetFacetConfigurations = () => {
    moveFacetConfiguration(props.facetConfigurations, configurationToDeleteSortOrder, (props.facetConfigurations.length - 1));
    props.facetConfigurations.pop();
    props.updateFacetConfigurations(props.facetConfigurations.map((facetConfiguration, index) => {
      facetConfiguration.sortOrder = index;
      return facetConfiguration;
    }));
  }

  const handleConfigurationClick = (configurationData: Partial<FacetConfiguration>) => {
    setConfigurationToEditId(configurationData.id ?? "");
    setAttribute(configurationData.attribute ?? "");
    setUi(configurationData.ui ?? "");
    setIsFilterFacet(configurationData.isFilterFacet ?? false);
    setHasCustomUi(configurationData.hasCustomUi ?? false);
    setSortOrder(configurationData.sortOrder ?? 0);
    setIsConfigurationEditOpen(true);
  }

  const handleConfigurationEditClose = () => {
    setConfigurationToEditId("");
    setAttribute("");
    setUi("");
    setIsFilterFacet(false);
    setHasCustomUi(false);
    setSortOrder(0);
    setIsConfigurationEditOpen(false);
  }

  const handleDeleteClick = (id: string, sortOrder: number) => {
    setConfigurationToDeleteId(id);
    setConfigurationToDeleteSortOrder(sortOrder);
    setConfigurationDeleteOpen(true);
  }

  const handleConfigurationDeleteClose = () => {
    setConfigurationToDeleteId("");
    setConfigurationToDeleteSortOrder(0);
    setConfigurationDeleteOpen(false);
  }

  const facetConfigurationFormProps: FacetConfigurationFormProps = {
    action: "Edit",
    isOpen: isConfigurationEditOpen,
    handleClose: handleConfigurationEditClose,
    handleAttributeChange,
    handleUiChange,
    handleIsFilterFacetChange,
    handleHasCustomUiChange,
    handleReloadFacetConfigurations,
    apiMethod: "put",
    apiPath: `/api/facet-configuration/${configurationToEditId}`,
    modalHeader: "Facet configuration",
    facetConfigurationData: {
      attribute,
      ui,
      isFilterFacet,
      hasCustomUi,
      sortOrder,
    }
  }

  const deleteFacetConfigurationProps: DeleteFacetConfigurationProps = {
    isOpen: configurationDeleteOpen,
    configurationToDeleteId,
    handleClose: handleConfigurationDeleteClose,
    handleResetFacetConfigurations,
  }

  return <>
    <br />
    <Table
      columns={[
        {
          header: 'Sort Order',
          hash: 'sortOrder',
          render: ({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) => <Button onClick={(e) => { e.preventDefault(); handleConfigurationClick({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) }} variant="subtle" isLoading={props.processing}>{sortOrder}</Button>,
          align: "center",
        },
        {
          header: 'Is Filter Facet?',
          hash: 'isFilterFacet',
          render: ({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) => <Button onClick={(e) => { e.preventDefault(); handleConfigurationClick({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) }} variant="subtle" isLoading={props.processing}>{isFilterFacet ? "Yes" : "No"}</Button>,
          align: "center",
        },
        {
          header: 'Attribute',
          hash: 'attribute',
          render: ({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) => <Button onClick={(e) => { e.preventDefault(); handleConfigurationClick({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) }} variant="subtle" isLoading={props.processing}>{attribute}</Button>,
          align: "center",
        },
        {
          header: 'UI',
          hash: 'ui',
          render: ({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) => <Button onClick={(e) => { e.preventDefault(); handleConfigurationClick({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) }} variant="subtle" isLoading={props.processing}>{!hasCustomUi ? ui : "N/A"}</Button>,
          align: "center",
        },
        {
          header: 'Custom UI',
          hash: 'customUi',
          render: ({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) => <Button onClick={(e) => { e.preventDefault(); handleConfigurationClick({ id, attribute, ui, sortOrder, isFilterFacet, hasCustomUi }) }} variant="subtle" isLoading={props.processing}>{hasCustomUi ? ui : "N/A"}</Button>,
          align: "center",
        },
        {
          header: 'Action',
          hash: 'action',
          render: ({ id, sortOrder }) => <Button onClick={(e) => { e.preventDefault(); handleDeleteClick(id || "", sortOrder) }} variant="secondary" isLoading={props.processing}>Delete</Button>,
          align: "center",
        },
      ]}
      items={props.facetConfigurations}
      onRowDrop={onFacetConfigurationsTableDragEnd}
      stickyHeader
    />
    <br />
    <FacetConfigurationForm {...facetConfigurationFormProps} />
    <DeleteFacetConfiguration {...deleteFacetConfigurationProps} />
  </>;
}
